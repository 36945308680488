.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
}

.items-container {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  overflow-y: scroll;
  padding: 10px;
}

.ReviewModalTable {
  width: 100%;
  /* padding: 8px 16px; */
}

.ReviewModalTable .ant-table-thead .ant-table-cell {
  /* background-color: #746BF8; */
  text-transform: uppercase;
  /* color: #FFF; */
}