@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-select {
  border-radius: 15px;
}

.custom-auto-complete .ant-input {
  border: none !important;
  border-radius: 5px;
  padding: 5px;
  background-color: #f4f4f4;
}