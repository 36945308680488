@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.custom-select {
  border-radius: 15px;
}

.custom-auto-complete .ant-input {
  border: none !important;
  border-radius: 5px;
  padding: 5px;
  background-color: #f4f4f4;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  background-color: #fff;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
}

.items-container {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  gap: 10px;
  overflow-y: scroll;
  padding: 10px;
}

.ReviewModalTable {
  width: 100%;
  /* padding: 8px 16px; */
}

.ReviewModalTable .ant-table-thead .ant-table-cell {
  /* background-color: #746BF8; */
  text-transform: uppercase;
  /* color: #FFF; */
}

.ant-popover {
}

.ant-popover-inner-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px !important;
}

.ant-popover-message-title {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ant-popover-buttons button {
  padding: 12px 16px;
  height: auto;
  flex: 1 1;
}
.pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  max-width: 100%;
}

.page {
  display: none;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  grid-gap: 40px;
  gap: 40px;
}

.page.active {
  display: flex;
}

.icon {
  font-size: 48px;
}

h2 {
  margin: 16px 0;
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.ellipses {
  display: flex;
  justify-content: center;
}

.ellipse {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  border-radius: 50%;
  background-color: #E4E4E7;
  cursor: pointer;
}

.ellipse.active {
  background-color: #746BF8;
}

.ConsignerDropOffPage {
  @media (max-width: 768px) {
    margin-bottom: 90px;
  }
}
.ConsignerDropOffPage .printable {
  display: none;
}

@media print {
  @page {
    size: landscape;
  }
  * {
    visibility: hidden;
  }

  .ConsignerDropOffPage .printable {
    display: block;
  }

  .ConsignerDropOffPage .printable .ant-table-content,
  .ConsignerDropOffPage .printable .ant-table-content * {
    visibility: visible;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.ant-layout::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ant-layout {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
